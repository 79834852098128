import React from 'react'
import ProductListItem from "../ListItem";
import style from './index.module.css'

function RelatedProducts (props) {
  return (
    <>
      <div className={style.titleContainer}>
        <h1 className={style.title}>Gerelateerde producten</h1>
      </div>
      <div className={style.list}>
        {props.relatedProducts.map(function(item, index){
          return (
            <div key={index}>
              <ProductListItem {...item} />
            </div>
          );
        })}
      </div>
    </>
  )
}

export default RelatedProducts