import React from "react";
import { Link, graphql } from "gatsby";
import * as PropTypes from "prop-types";
import Img from "gatsby-image";
import Helmet from "react-helmet";
import { formatCurrency } from '../../util/format'
import Layout from "../../layouts";
import BlockContent from "@sanity/block-content-to-react"
import ProductOptions from "./Options";
import RelatedProducts from "./RelatedProducts";
import ProductImages from "./Images";
import style from './index.module.css'

const propTypes = {
  data: PropTypes.object.isRequired,
}

class ProductTemplate extends React.Component {
  render() {
    const info = this.props.data.info
    const product = this.props.data.product
    return (
      <Layout>
        <article>
          <Helmet>
            <title>{product.name}</title>
            <meta name="description" content={product.shortDescription} />
          </Helmet>
          <div className="panel">
            <div className="row v-align-center content-text">
              <div className="medium-2 columns">
                <Link to="/shop">&#8592; Overzicht</Link>
              </div>
              <div className="medium-10 columns">
                <h1 className="portfolio__title">{product.name}</h1>
              </div>
            </div>
          </div>
          <div className={style.content}>
            {product.overviewImage &&
              <div className={style.imageContainer}>
                <Img className={style.image} fluid={product.overviewImage.asset.fluid} alt={product.name} />
              </div>
            }
            <div className={style.details}>
              {product.productOptions &&
                <div className={style.options}>
                  <h3 className={style.optionsTitle}>Opties</h3>
                  <ProductOptions options={product.productOptions} />
                </div>
              }
            </div>
            <div className={style.descriptionContainer}>
              {product._rawIntro &&
                <div className={style.intro}>
                  <BlockContent blocks={product._rawIntro} />
                </div>
              }
              {product._rawDescription &&
                <div className={style.description}>
                  <BlockContent blocks={product._rawDescription} />
                </div>
              }
              {product._rawSpecificaties &&
                <div>
                  <h3>Specificaties</h3>
                  <BlockContent blocks={product._rawSpecificaties} />
                </div>
              }
              {product._rawLevertijd &&
                <div>
                  <h3>Levertijd</h3>
                  <BlockContent blocks={product._rawLevertijd} />
                </div>
              }
              {info._rawBestelinformatie &&
                <div>
                  <h3>Bestellen</h3>
                  <BlockContent blocks={info._rawBestelinformatie} />
                </div>
              }
              <div className={style.price}>{formatCurrency(product.price * 1.21)} incl. btw.</div>
            </div>
          </div>
          {product.images &&
            <div className={style.imageList}>
              <ProductImages images={product.images} />
            </div>
          }
        </article>
        {product.relatedProducts &&
          <section className={style.relatedProducts}>
            <RelatedProducts relatedProducts={product.relatedProducts} />
          </section>
        }
      </Layout>
    )
  }
}

ProductTemplate.propTypes = propTypes

export default ProductTemplate

export const pageQuery = graphql`
  query($id: String!) {
    product: sanityProduct(id: { eq: $id }) {
    id
    slug {
      current
    }
    name
    price
    vatRate
    _rawShortDescription
    _rawIntro
    _rawDescription
    _rawSpecificaties
    _rawLevertijd
    vehicle {
      name
      brand
      type
    }
    overviewImage {
      _key
      asset {
        fluid(maxWidth: 830) {
          ...GatsbySanityImageFluid_withWebp
        }
      }
    }
    images {
      _key
      asset {
        fluid(maxWidth: 540) {
          ...GatsbySanityImageFluid_withWebp
        }
      }
    }
    productOptions {
      id
      title
      _type
      colors {
        id
        ref
        name
        shortDescription
        image {
          _key
          asset {
            fluid(maxWidth: 185) {
              ...GatsbySanityImageFluid_withWebp
            }
          }
        }
      }
    }
    relatedProducts {
      id
      slug {
        current
      }
      name
      price
      _rawShortDescription
      overviewImage {
        asset {
          fluid(maxWidth: 380) {
            ...GatsbySanityImageFluid_withWebp
          }
        }
      }
    }
  }
  info: sanityPaginaShop {
    _rawBestelinformatie
  }
}
`