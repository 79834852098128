import React from 'react'
import Img from "gatsby-image"
import style from './index.module.css'

function ProductOptions (props) {
  const options = props.options
  return (
    <div className={style.list}>
      {options && options.map(function(option) {
        return (
          <div className={style.option} key={option.id}>
            <h3 className={style.title}>{option.title}</h3>
            {option.colors &&
              <div className={style.colorList}>
                {option.colors.map(function(color) {
                  return (
                    <div className={style.color} key={color.id}>
                      <h4 className={style.colorName}>{color.name}</h4>
                      <Img className={style.colorImage} fluid={color.image.asset.fluid} alt={color.ref} key={color.image._key} />
                      {/* {color.shortDescription &&
                        <div className={style.colorDescription}>{color.shortDescription}</div>
                      } */}
                    </div>
                  );
                })}
              </div>
            }
          </div>
        );
      })}
    </div>
  )
}

export default ProductOptions