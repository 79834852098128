import React from 'react'
import Img from "gatsby-image"
import style from './index.module.css'

function ProductImages (props) {
  const images = props.images
  return (
    <>
      {images.map(function(image) {
        return (
          <Img className={style.imageListImage} fluid={image.asset.fluid} alt="" key={image._key} />
        );
      })}
    </>
  )
}

export default ProductImages